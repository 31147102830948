import {
	$formulario1,
	$formulario2,
	$inputs_contact,
	$inputs_modal,
	$textarea,
	$contact_button,
	$modal_button,
} from "../dom.js";
import axios from "axios";

const expresiones = {
	nombres: /^[a-zA-ZÀ-ÿ\s]{5,30}$/, // Letras y espacios, pueden llevar acentos.
	correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
	celular: /^\d{7,14}$/, // 7 a 14 numeros.
	mensaje: /^[a-zA-ZÀ-ÿ0-9\s]{10,300}$/,
	curso: /^[a-zA-ZÀ-ÿ\s]{5,30}$/,
};

const campos = {
	nombres: false,
	correo: false,
	celular: false,
	mensaje: false,
	curso: false,
};

const validarFormulario = (e) => {
	switch (e.target.name) {
		case "nombres":
			validarCampo(e.target);
			break;
		case "correo":
			validarCampo(e.target);
			break;
		case "celular":
			validarCampo(e.target);
			break;
		case "mensaje":
			validarCampo(e.target);
			break;
		case "curso":
			validarCampo(e.target);
			break;
	}
};

const validarCampo = (campo) => {
	let expresion = campo.name;
	if (expresiones[expresion].test(campo.value)) {
		campo.parentNode.classList.remove("input-group--incorrecto");
		campo.parentNode.classList.add("input-group--correcto");
		campo.nextElementSibling.classList.remove("fa-times-circle");
		campo.nextElementSibling.classList.add("fa-check-circle");
		campo.parentNode.nextElementSibling.classList.remove(
			"input-group__error--active"
		);
		campos[expresion] = true;
	} else {
		campo.parentNode.classList.remove("input-group--correcto");
		campo.parentNode.classList.add("input-group--incorrecto");
		campo.nextElementSibling.classList.remove("fa-check-circle");
		campo.nextElementSibling.classList.add("fa-times-circle");
		campo.parentNode.nextElementSibling.classList.add(
			"input-group__error--active"
		);
		campos[expresion] = false;
	}
};

const resetearCampos = (campos) => {
	for (let key in campos) {
		campos[key] = false;
	}
};

document.addEventListener("DOMContentLoaded", function () {
	$inputs_contact.forEach((input) => {
		input.addEventListener("keyup", validarFormulario);
		input.addEventListener("blur", validarFormulario);
		$textarea.addEventListener("keyup", validarFormulario);
		$textarea.addEventListener("blur", validarFormulario);
	});

	$inputs_modal.forEach((input) => {
		input.addEventListener("keyup", validarFormulario);
		input.addEventListener("blur", validarFormulario);
	});

	if (window.location.pathname.search(/contacto/) !== -1) {
		$formulario1.addEventListener("submit", (e) => {
			const {nombres, correo, celular, mensaje} = campos;
			e.preventDefault();
			if (nombres & correo & celular & mensaje) {
				(async () => {
					try {
						$contact_button.textContent = "Cargando...";
						$contact_button.disabled = true;
						await axios.post(`/enviar-mensaje?_csrf=${csrfToken}`, {
							nombres: document.querySelector(
								".formulario1 input[name='nombres']"
							).value,
							correo: document.querySelector(
								".formulario1 input[name='correo']"
							).value,
							celular: document.querySelector(
								".formulario1 input[name='celular']"
							).value,
							mensaje: document.querySelector(
								".formulario1 textarea[name='mensaje']"
							).value,
						});
						document
							.querySelector(".formulario1 .input-group__mensaje")
							.classList.remove("input-group__mensaje--active");
						document
							.querySelector(".formulario1 .input-group__mensaje-exito")
							.classList.add("input-group__mensaje-exito--active");
						$formulario1.querySelectorAll(".input-group").forEach((input) => {
							input.classList.remove("input-group--correcto");
						});
						$formulario1.reset();
						resetearCampos(campos);
						$contact_button.disabled = false;
						$contact_button.textContent = "SOLICITAR INFORMACIÓN";
					} catch (err) {
						const message = err.response
							? err.response.data.message
							: err.message;
						document
							.querySelector(".formulario1 .input-group__mensaje-exito")
							.classList.remove("input-group__mensaje-exito--active");
						document
							.querySelector(".formulario1 .input-group__mensaje")
							.classList.add("input-group__mensaje--active");
						document.querySelector(
							".formulario1 .input-group__mensaje"
						).textContent = message;
						$contact_button.disabled = true;
						$contact_button.textContent = "SOLICITAR INFORMACIÓN";
					}
				})();
			} else {
				document
					.querySelector(".formulario1 .input-group__mensaje-exito")
					.classList.remove("input-group__mensaje-exito--active");
				document
					.querySelector(".formulario1 .input-group__mensaje")
					.classList.add("input-group__mensaje--active");
			}
		});
	}
	if ($formulario2) {
		$formulario2.addEventListener("submit", (e) => {
			const {nombres, correo, celular, curso} = campos;
			e.preventDefault();
			if (nombres & correo & celular & curso) {
				(async () => {
					try {
						$modal_button.textContent = "Cargando...";
						$modal_button.disabled = true;
						await axios.post(`/enviar-mensaje?_csrf=${csrfToken}`, {
							nombres: document.querySelector(
								".formulario2 input[name='nombres']"
							).value,
							correo: document.querySelector(
								".formulario2 input[name='correo']"
							).value,
							celular: document.querySelector(
								".formulario2 input[name='celular']"
							).value,
							curso: document.querySelector(".formulario2 input[name='curso']")
								.value,
						});
						document
							.querySelector(".formulario2 .input-group__mensaje")
							.classList.remove("input-group__mensaje--active");
						document
							.querySelector(".formulario2 .input-group__mensaje-exito")
							.classList.add("input-group__mensaje-exito--active");

						$formulario2.querySelectorAll(".input-group").forEach((input) => {
							input.classList.remove("input-group--correcto");
						});
						$formulario2.reset();
						resetearCampos(campos);
						$modal_button.disabled = false;
						$modal_button.textContent = "SOLICITAR INFORMACIÓN";
					} catch (err) {
						const message = err.response
							? err.response.data.message
							: err.message;
						document
							.querySelector(".formulario2 .input-group__mensaje-exito")
							.classList.remove("input-group__mensaje-exito--active");
						document
							.querySelector(".formulario2 .input-group__mensaje")
							.classList.add("input-group__mensaje--active");
						document.querySelector(
							".formulario2 .input-group__mensaje"
						).textContent = message;
						$modal_button.disabled = true;
						$modal_button.textContent = "SOLICITAR INFORMACIÓN";
					}
				})();
			} else {
				document
					.querySelector(".formulario2 .input-group__mensaje-exito")
					.classList.remove("input-group__mensaje-exito--active");
				document
					.querySelector(".formulario2 .input-group__mensaje")
					.classList.add("input-group__mensaje--active");
			}
		});
	}
});
